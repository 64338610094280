import React from "react";
import {
  Hero,
  Bids,
  Top_collection,
  Tranding_category,
  NewseLatter,
  Feature_collections
} from "../../components/component";
import Financialnews from "../../components/blog/financialnews";
import Meta from "../../components/Meta";

// Page-level SSR function
export async function getServerSideProps() {
  try {
    // Fetch data for printables
    const res = await fetch( `${process.env.BASE_URL}/api/printables`); // Replace with your actual API URL
    const data = await res.json();
    //console.log("API PRINT CALL  " , data)
    return {
      props: {
        printables: data.printables, // Pass the fetched data as props
      },
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return {
      props: {
        printables: [], // In case of error, return an empty array
      },
    };
  }
}

const Home_1 = ({ printables }) => {
  return (
    <main>
      <Meta title="Filamojo" />
      <Feature_collections printables={printables} />
      {/* <Auctions_categories /> */}
      {/* <Hero /> */}
      {/* <Bids /> */}
      {/* <Top_collection /> */}
      {/* <Tranding_category /> */}
      <NewseLatter />
      <Financialnews />
    </main>
  );
};

export default Home_1;
