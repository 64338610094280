export const financial_data = [
	{
		title: 'FilaMojo’s Guide to 3D Printing for Cosplayers',
		desc: 'It’s remarkable how Additive manufacturing, a technology once predominantly used to prototype airplane and automobile parts is now publicly available to create 3D-printed shoes, dresses, suits, toys, action figures, display …',
		id: 1,
		date: '5 Fab, 2022',
		link: 'https://www.filamojo.com/learn/3d-printing-for-cosplayers/'
	},
	{
		title: 'Enabling the Disabled: 3D printed Prosthetics Giving the Impaired a New Lease of Life',
		desc: 'War has sadly been an easy answer to the world’s simplest problems. The outcome of humanity’s oldest instinct poses a difficult question for many: the loss of life and property. …',
		id: 2,
		date: '5 Fab, 2022',
		link: 'https://www.filamojo.com/learn/3d-printed-prosthetics-enabling-the-disabled/'
	},
	{
		title: 'Chocolate 3D printing working, benefits and limitations',
		desc: 'The chocolate industry is yet to peak. But the advent of 3D printing has definitely given the chocolate industry a never seen high. Never knew that 3D printing can be …',
		id: 3,
		date: '5 Fab, 2022',
		link: 'https://www.filamojo.com/learn/chocolate-3d-printing-working-benefits-and-limitations/'
	},
	{
		title: 'Tips to improve 3d print quality',
		desc: 'In this article, we share with you the top tips to improve 3D print quality. Our tips cover nearly everything; from improving surface finish, fixing stringing issues, printed layer issues, …',
		id: 4,
		date: '5 Fab, 2022',
		link: 'https://www.filamojo.com/learn/improve-3d-print-quality/'
	},
];
