import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import Home_1 from "./home";

// Page-level SSR function
export async function getServerSideProps() {
  try {
    // Fetch data for printables
    const res = await fetch(`${process.env.BASE_URL}/api/printables`); // Replace with your actual API URL
    const data = await res.json();
    //console.log("API PRINT CALL  " , data)
    return {
      props: {
        printables: data.printables, // Pass the fetched data as props
      },
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return {
      props: {
        printables: [], // In case of error, return an empty array
      },
    };
  }
}

export default function Home({printables}) {
  const { scrollRef } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  });

  
  return (
    <>
      <Home_1  printables={printables}/>
    </>
  );
}
